<template>
  <!-- Addresses form -->
  <form
    class="user-forms-address flow"
    @submit="onSubmit">

    <div class="group">
      <!-- Street -->
      <forms-input
        autocomplete
        autofocus
        ref="street"
        name="address-line1"
        :errors="getErrors('street')"
        required
        :placeholder="$t('user-portal.placeholder')"
        @input="onRemoveError('street')"
        v-model="address.street"
      >{{ $t('user-portal.street') }}</forms-input>

      <!-- Number -->
      <forms-input
        autocomplete
        ref="number"
        name="address-line2"
        :errors="getErrors('number')"
        required
        class="view-checkout-review__number"
        :placeholder="$t('user-portal.placeholder')"
        @input="onRemoveError('number')"
        v-model="address.number"
      >{{ $t('user-portal.street_number') }}</forms-input>
    </div>

    <div class="group">
      <!-- Country -->
      <forms-select
        :key="key"
        ref="country"
        :disabled="!hasMultipleCountries"
        :errors="getErrors('country_id')"
        :options="countries"
        required
        :placeholder="$t('user-portal.select_placeholder')"
        @change="onRemoveError('country_id')"
        v-model="address.country_id"
      >{{ $t('user-portal.country_id') }}</forms-select>
    </div>

    <div class="group">
      <!-- Zip -->
      <forms-input
        autocomplete
        ref="zip"
        :errors="getErrors('zip')"
        required
        :placeholder="$t('user-portal.placeholder')"
        @input="onRemoveError('zip')"
        v-model="address.zip"
      >{{ $t('user-portal.zip') }}</forms-input>

      <!-- City -->
      <forms-input
        autocomplete
        ref="city"
        :errors="getErrors('city')"
        required
        :placeholder="$t('user-portal.placeholder')"
        @input="onRemoveError('city')"
        v-model="address.city"
      >{{ $t('user-portal.city') }}</forms-input>
    </div>
  </form>
</template>

<script>
import MixinError from '@/helpers/errors'

export default {
  name: 'FormsAddress',

  inject: ['$user'],

  mixins: [ MixinError ],

  model: {
    event: 'change',
    prop: 'address',
  },

  props: {
    address: {
      type: Object,
      required: true
    },

    errors: {},

    scope: {
      type: String,
      default: 'shipping'
    }
  },

  data() {
    return {
      key: 0
    }
  },

  computed: {
    countries() {
      let ret = this.$user.countries || [];
      return ret.map(c => Object.assign(c, {label: c.name, value: c.id}))
    },

    country() {
      let ret = null;

      if(this.address.country_id != null) {
        ret = this.countries.find(c => c.id == this.address.country_id);
      }

      return ret
    },

    hasMultipleCountries() {
      return this.countries.length > 1
    },

    hasPostalCode() {
      return this.country != null && this.country.postalCodes && this.country.postalCodes.length > 0
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();
      return
    },

    async reset() {
      try {
        await this.$user.getCountries({})
        this.address.scope = this.scope
      } catch(e) {
        $console.error(e)
      }
    },
  },

  mounted() {
    this.reset();
  }
}
</script>
