<template>
  <main>
    <div class="container">
      <!-- Header -->
      <header class="user__header">
        <navigations-breadcrumb :children="breadcrumbs" />

        <div class="row">
          <h1 class="user__title">{{ $t('user-portal.user_addresses_title') }}</h1>
        </div>
      </header>

      <!-- Loading -->
      <div
        v-if="loading"
        class="user__loader">
        <ui-loader />
      </div>

      <!-- Body:Content -->
      <div
        v-if="!loading"
        class="user__body flow">
        <div class="-grid">
          <card-address
            v-for="(address, i) in addresses"
            :key="i"
            :address="address"
            :editable="canAdd"
            @edit="onEdit"
            @default="onSetAsDefault"
            @delete="onDelete"
          />

          <div
            class="user-card -is-empty"
            v-if="canAdd"
            @click="onAdd">
            <div class="user-card__figure">
              <ui-icon
                class="icon"
                glyph="plus"
              />
            </div>

            <p class="user-card__label">{{ $t('user-portal.add_new_address') }}</p>
          </div>
        </div>

        <data-empty
          :description="$t('user-portal.user_no_addresses_description')"
          :title="$t('user-portal.user_no_addresses')"
          v-if="empty && !canAdd"
        />
      </div>
    </div>

    <!-- Modal Address -->
    <modal-address
      v-if="currentAddress && canAdd"
      :address="edition"
      :errors="errors"
      @close="onClose"
    />
  </main>
</template>

<script>
import CardAddress from '../../components/ui/card-address'
import ModalAddress from '../../components/modals/address'

import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'

import MixinError from '@/helpers/errors'

export default {
  name: 'UserAddresses',

  components: {
    CardAddress,
    ModalAddress,
    NavigationsBreadcrumb
  },

  inject: [
    '$embed',
    '$user'
  ],

  mixins: [ MixinError ],

  data() {
    return {
      breadcrumbs: [
        { label: this.$t('user-portal.user_account_title'), href: { name: 'sayl-user-portal.home' } },
        { label:  this.$t('user-portal.user_addresses_title'), href: '#' }
      ],
      currentAddress: null,
      edition: {},
      errors: {},
      key: 1,
      loading: false,
    }
  },

  computed: {
    addresses() {
      return this.$basil.get(this.user, 'addresses', [])
    },

    countries() {
      return this.$basil.get(this.$embed, 'shop.countries')
    },

    canAdd() {
      return true
    },

    empty() {
      return this.addresses.length === 0
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    onAdd() {
      this.currentAddress = {}
      this.edition = {
        street: null,
        city: null,
        number: null,
        country: null,
        countryId: null,
        zip: null,
      }

      this.errors = {}
    },

    onClose() {
      this.currentAddress = null
      this.edition = {}
      this.errors = {}
    },

    async onConfirm() {
      try { 
        this.errors = {}
        this.loading = true
        
        await this.$user.setAddress({ address: this.edition })
      } catch(e) {
        this.handleErrors(e)
      } finally {
        this.loading = false
      }
    },

    onDelete(address){
      this.$confirm({
        title: this.$t('user-portal.remove_address_title'),
        description: this.$t('user-portal.remove_address_description'),
        onPrimary: async () => {
          try {
            this.loading = true
            await this.$user.removeAddress({ id: address.id })
          } catch(e) {
            this.$notification({
              title: this.$t('user-portal.save_error_title'),
              type: 'error'
            })
            $console.error(e)
          } finally {
            this.loading = false
          }
        }
      })
    },

    onEdit(address) {
      this.edition = address.toAPI()

      this.currentAddress = address
      this.errors = {}
      this.key++
    },

    async onSetAsDefault(address) {
      try {
        this.loading = true
        let found = this.addresses.find(a => a.isDefault === true)

        if(!this.$basil.isNil(found)) {
          found.isDefault = false
          await this.$user.setAddress({ address: found.toAPI() })
        }

        address.isDefault = true
        await this.$user.setAddress({ address: address.toAPI() })

        this.$notification({
          title: this.$t('user-portal.save_success_title'),
          type: 'success'
        })
      } catch(e) {
        this.$notification({
          title: this.$t('user-portal.save_error_title'),
          type: 'error'
        })
        $console.error(e)
      } finally {
        this.loading = false
      }
    }
  },

  mounted() {
    this.edition = {}
    this.errors = {}
    this.key++
  },
}
</script>
