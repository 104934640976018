<template>
  <layout-modal
    :classes="classes" 
    visible
    closable
    :loading="loading"
    @close="close">

    <forms-address
      :key="key"
      v-model="address"
      :errors="errors"
    />

    <template v-slot:footer>
      <actions-button
        @click="close"
        :size="$pepper.Size.S"
      >{{$t('user-portal.cancel')}}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        @click="onConfirm"
        :disabled="loading"
        :size="$pepper.Size.S"
      >{{$t('user-portal.confirm')}}</actions-button>
    </template>

  </layout-modal>
</template>

<script>
import LayoutModal from '@/components/layouts/modal'

import FormsAddress from '../forms/address'

export default {
  name: 'ModalAddress',

  inject: ['$user'],

  model: {
    prop: 'address',
    event: 'change',
  },

  props: {
    address: {},
  },

  data() {
    return {
      loading: false,
      errors: {},
      key: 1
    }
  },

  components: {
    FormsAddress,
    LayoutModal
  },

  computed: {
    classes(){
      return {
        'view-user-address': true
      }
    }
  },

  methods: {
    close(){
      this.$emit('close')
    },

    handleErrors(e) {
      let status = this.$basil.get(e, 'response.status')
      if(status === 422) {
        let errors = this.$basil.get(e, 'response.data.errors')

        if(!this.$basil.isNil(errors)) {
          let keys = Object.keys(errors)
          keys.forEach(k => this.errors[k] = [this.$t('user-portal.field_is_required')])// [this.$t(`user-portal.${errors[k]}`)])
        }
      }

      this.key++
    },

    async onConfirm() {
      this.loading = true
      this.errors = {}

      try {
        await this.$user.setAddress({ address: this.address })
        this.close()
      } catch(e) {
        this.handleErrors(e)
      } finally {
        this.loading = false
      }
    }
  },

  mounted() {
    this.errors = {}
  }
}
</script>
